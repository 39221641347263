import { router } from "@/main";
import { ROUTING } from "@/routing/routes";
import { Api as ApiUser, CustomerGeneralities, CustomerPermissionsDTO } from "@/service/UserModel";
import { isValidUrl } from "@/utils/genericUtils";
import {
  BRAND_MAP,
  eventProvider,
  legacyIdMap,
  mockService,
  sendErrorAnalytics,
  sendEventAnalytics,
  translation,
  type BrandObject,
} from "@mercury/common";
import { isUserInternal } from "@utils/userUtils";
import { CustomerDTO } from "./UserModel";

export interface getUserParams {
  useEventProvider?: boolean;
  onBehalf?: boolean;
  customerOnBehalfId?: string;
  currentCustomerId: string;
}
export const getCustomerInfo = (params: getUserParams): Promise<void | CustomerDTO[]> => {
  const {
    useEventProvider = true,
    onBehalf = false,
    customerOnBehalfId = "",
    currentCustomerId = "",
  } = params || {};
  const mock = window.globalEnvVariables?.USE_MOCK_ACCOUNT;

  const apiUser = new ApiUser();

  const query = {
    onBehalf: onBehalf,
    customerOnBehalfId: customerOnBehalfId,
    currentCustomerId: currentCustomerId,
  };

  return (mock ? mockService("/mock/user/customer.json") : apiUser.priv.getCustomerInfo(query))
    .then((res: { data: CustomerDTO[] }) => {
      const user = res.data;
      if (useEventProvider) {
        //share user info through event provider
        const eventProviderInstance = eventProvider.istantiateEventProvider();
        eventProviderInstance?.emit(eventProvider.EventChannel.customerInfo, user);

        //share user brands
        const brandMap = {};
        user?.forEach((soldTo) => {
          if (soldTo.division && !brandMap[soldTo.division]) {
            brandMap[soldTo.division] = true;
          }
        });
        const brands = getBrandsInfoFromId(Object.keys(brandMap));
        console.log("Sold-to brands", { brands });
        eventProviderInstance?.emit(eventProvider.EventChannel.brands, brands);
      }
      return user;
    })
    .catch((err) => {
      console.error("getCustomerInfo", { err });

      //analytics
      sendErrorAnalytics({
        description: "Error getting customer info",
        techInfo: err,
      });
    });
};

export interface getUserPermissionsParams {
  useEventProvider?: boolean;
}
export const getUserPermissions = (
  params?: getUserPermissionsParams,
): Promise<void | CustomerPermissionsDTO> => {
  const { useEventProvider = true } = params || {};
  const mock = window.globalEnvVariables?.USE_MOCK_ACCOUNT;

  const apiUser = new ApiUser();

  return (
    mock ? mockService("/mock/user/userPermissions.json") : apiUser.priv.getAllCustomerPermissions()
  )
    .then((res: { data: CustomerPermissionsDTO }) => {
      const userPermissions = res.data;

      if (
        !userPermissions ||
        Object.keys(userPermissions).length === 0 ||
        userPermissions.portalPrivileges?.length === 0
      ) {
        router.push(ROUTING.waiting.url);
        return;
      }

      if (userPermissions && !userPermissions.portalConfiguration?.[0]?.language) {
        const language = translation.languagesList[userPermissions.language?.toLowerCase()]
          ? translation.languagesList[userPermissions.language?.toLowerCase()].id
          : translation.getFallbackLanguage();

        if (
          userPermissions.portalConfiguration &&
          userPermissions.portalConfiguration?.length > 0
        ) {
          userPermissions.portalConfiguration[0].language = language;
        } else {
          userPermissions.portalConfiguration = [{ language: language }];
        }
      }

      if (useEventProvider) {
        const eventProviderInstance = eventProvider.istantiateEventProvider();
        eventProviderInstance?.emit(
          eventProvider.EventChannel.userInfoPermissions,
          userPermissions,
        );
      }
      return userPermissions;
    })
    .catch((err) => {
      console.error("getUserPermissions", { err });
      if (err?.response?.status === 403) {
        router.push(ROUTING.waiting.url);
      } else {
        //analytics
        sendErrorAnalytics({
          description: "Error getting the user permissions",
          techInfo: err,
        });

        throw err;
      }
    });
};

export interface getSoldTosParams {
  useEventProvider?: boolean;
}
export const getSoldTos = (params?: getSoldTosParams): Promise<void | CustomerGeneralities[]> => {
  const { useEventProvider = true } = params || {};

  const mock = window.globalEnvVariables?.USE_MOCK_ACCOUNT;

  const apiUser = new ApiUser();

  return (mock ? mockService("/mock/user/soldtos.json") : apiUser.priv.getUsersCustomers())
    .then((res: { data: CustomerGeneralities[] }) => {
      //sort sold-to alphabetically
      const soldTos = (res.data || [])?.sort((a, b) =>
        (a.legalName || "").toLowerCase().localeCompare((b.legalName || "").toLowerCase()),
      );

      if (useEventProvider) {
        //share user info through event provider
        const eventProviderInstance = eventProvider.istantiateEventProvider();
        eventProviderInstance?.emit(eventProvider.EventChannel.soldToList, soldTos);
      }
      return soldTos;
    })
    .catch((err) => {
      console.error("getSoldTos", { err });

      //analytics
      sendErrorAnalytics({
        description: "Error getting the sold-to list",
        techInfo: err,
      });

      throw err;
    });
};

export interface setSelectedSoldToParams {
  soldToId: string;
}
export const setSelectedSoldTo = (params: setSelectedSoldToParams): Promise<void> => {
  const mock = window.globalEnvVariables?.USE_MOCK_ACCOUNT;

  const apiUser = new ApiUser();

  const query = {
    customer: params.soldToId,
  };

  return (
    mock ? mockService("/mock/user/setSelectedSoldTo.json") : apiUser.priv.saveLastLogin(query)
  )
    .then((res: { data: void }) => {
      //just 200 OK, no body response
      return;
    })
    .catch((err) => {
      console.error("Error in setSelectedSoldTo", { err });

      //analytics
      sendErrorAnalytics({
        description: "Error selecting the customer",
        techInfo: err,
      });

      throw err;
    });
};

export interface setAcceptStandardTermsParams {
  soldToId: string;
}
export const setAcceptStandardTerms = (params: setAcceptStandardTermsParams): Promise<void> => {
  const mock = window.globalEnvVariables?.USE_MOCK_ACCOUNT;

  const apiUser = new ApiUser();

  const query = {
    customer: params.soldToId,
  };

  return (
    mock
      ? mockService("/mock/user/acceptStandardTerms.json")
      : apiUser.priv.acceptTermsAndConditions(query)
  )
    .then((res: { data: void }) => {
      //just 200 OK, no body response
      return;
    })
    .catch((err) => {
      console.error("Error in setAcceptStandardTerms", { err });

      //analytics
      sendErrorAnalytics({
        description: "Error accepting the terms & conditions",
        techInfo: err,
      });

      throw err;
    });
};

export interface setAcceptStandardTermsWithTokenParams {
  token: string;
}
export const setAcceptStandardTermsWithToken = (
  params: setAcceptStandardTermsWithTokenParams,
): Promise<void> => {
  const apiUser = new ApiUser();

  const query = {
    token: params.token,
  };

  return apiUser.pub
    .acceptTermsAndConditions(query)

    .then((res: { data: void }) => {
      //just 200 OK, no body response
      return;
    })
    .catch((err) => {
      console.error("Error in setAcceptStandardTerms", { err });

      //analytics
      sendErrorAnalytics({
        description: "Error accepting the terms & conditions",
        techInfo: err,
      });

      throw err;
    });
};

const PERMISSIONS_MAP = {
  b2b: "B2B_ACCESS_ENABLEMENT",
  orderInquiry: "INQUIRY_ACCESS_ENABLEMENT",
  report: "REPORT_ACCESS_ENABLEMENT",
  returns: "RETURNS_ACCESS_ENABLEMENT",
  warranty: "WARRANTY_ACCESS_ENABLEMENT",
  showroom: "DIGITAL_SHOWROOM_ACCESS_ENABLEMENT",
  companyInfo: "COMPANY_INFORMATION_VISIBILITY",
  loginSecurity: "LOGIN_AND_SECURITY_PAGE",
  faq: "FAQ_AND_HELP_CENTER",
  onBehalf: "ON_BEHALF_OF_NAVIGATION_ENABLEMENT",
  document: "DOCUMENTS_SECTION_ENABLEMENT",
  documentInvoice: "INVOICES_VISIBILITY",
  documentPayment: "PAYMENTS_VISIBILITY",
  documentCreditNote: "CREDIT_NOTES_VISIBILITY",
  notification: "NOTIFICATIONS_ENABLEMENTS",
  alert: "ALERTS_ENABLEMENT",
  ticket: "TICKETING",
  orderManagement: "ORDER_MANAGEMENT_ACCESS",
  reportDownload: "REPORT_DOWNLOAD",
  cms: "CMS_ACCESS",
  etaf: "ETAF_ACCESS",
  admin: "ADMIN_TOOL_ACCESS",
  etafConfig: "ETAF_CONFIG_ACCESS",
  showroomConfig: "DIGITAL_SHOWROOM_CONFIG_ACCESS",
  cloneUser: "CLONE_USERS",
  dataPrivacy: "MY_POLICIES_ENABLEMENT",
  termsAndConditions: "TERMS_AND_CONDITIONS_ENABLEMENT",
  termsAndConditionsSendByEmail: "ON_BEHALF_RESEND_TERMS_AND_CONDITIONS_EMAIL",
  contacts: "MY_VF_CONTACTS_ENABLEMENT",
  sapControlReport: "SAP_CONTROL_REPORT_ACCESS_ENABLEMENT",
  mmConfig: "MM_CONFIG_ACCESS_ENABLEMENT",
  b2bCms: "OLD_CMS_ACCESS_ENABLEMENT",
  vasMenu: "VAS_MENU_CARD_AND_COST_SIMULATOR",

  // userGuide: "USER_GUIDE",
};

export const mapUserPermissions = (
  userPermissions: CustomerPermissionsDTO | undefined,
  selectedSoldTo: string,
) => {
  const eventProviderInstance = eventProvider.istantiateEventProvider();

  let permissions = undefined;
  const isInternal = isUserInternal(userPermissions!);

  if (userPermissions && (selectedSoldTo || isInternal)) {
    //get privileges for the selected sold-to
    const portalPrivileges = isInternal
      ? userPermissions?.portalPrivileges[0]
      : userPermissions?.portalPrivileges?.find(
          (portalPrivilegesBySoldto) => portalPrivilegesBySoldto.customer === selectedSoldTo,
        );
    console.log("portalPrivileges", { portalPrivileges });
    const portalPrivilegesEnabled = portalPrivileges?.enabled || [];

    permissions = {
      username: userPermissions.userName,
      fullname: userPermissions.name,
      passwordExpirationDate: userPermissions.passwordExpirationDate,
      account: !isInternal, //only external users
      companyInfo: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.companyInfo),
      loginSecurity: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.loginSecurity),
      report: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.report),
      notifications:
        portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.notification) &&
        !window.globalEnvVariables?.HIDE_UPDATES,
      alerts:
        portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.alert) &&
        !window.globalEnvVariables?.HIDE_UPDATES,
      ticket: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.ticket),
      ticketWrite: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.ticket), //permission to create a new ticket or add a new comment, not permitted in on behalf mode
      orderInquiry: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.orderInquiry),
      orderManagement: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.orderManagement),
      ecommerceInternal: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.b2b) && isInternal,
      ecommerceExternal: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.b2b) && !isInternal,
      returns: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.returns),
      warranty: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.warranty),
      onBehalf: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.onBehalf) && isInternal,
      cms: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.cms),
      etaf: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.etaf),
      etafConfiguration: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.etafConfig),
      sapControlReport: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.sapControlReport),
      mmConfig: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.mmConfig),
      b2bCms: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.b2bCms),
      adminTool: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.admin),
      digitalShowroom: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.showroom),
      digitalShowroomConfiguration: portalPrivilegesEnabled?.includes(
        PERMISSIONS_MAP.showroomConfig,
      ),
      faq: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.faq),
      document: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.document),
      documentInvoice: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.documentInvoice),
      documentPayment: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.documentPayment),
      documentCreditNote: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.documentCreditNote),
      reportDownload: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.reportDownload),
      cloneUser: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.cloneUser), //feature out of scope for now
      dataPrivacy: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.dataPrivacy),
      termsAndConditions: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.termsAndConditions),
      termsAndConditionsSendByEmail: portalPrivilegesEnabled?.includes(
        PERMISSIONS_MAP.termsAndConditionsSendByEmail,
      ),
      contacts: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.contacts),
      vasMenu: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.vasMenu),
      // userGuide: portalPrivilegesEnabled?.includes(PERMISSIONS_MAP.userGuide),
      internal: isInternal,
      persona: portalPrivileges?.role,
      legacyBrandList: portalPrivileges?.legacyBrandList,
      soldToId: selectedSoldTo, //just to know to which sold-to the permissions are related
    };
  }

  //share user permissions through event provider
  eventProviderInstance?.emit(eventProvider.EventChannel.permissions, { permissions: permissions });

  if (permissions?.internal) {
    if (userPermissions?.activeBrands && userPermissions.activeBrands.length > 0) {
      //if user is internal --> get brands from permissions and not from customer
      eventProviderInstance?.emit(
        eventProvider.EventChannel.brands,
        getBrandsInfoFromId(userPermissions.activeBrands),
      );
    }
  }
};

const getBrandsInfoFromId = (brandsId: string[]) => {
  let brands: BrandObject[] = [];

  //map brand ids with our objects inside BRAND_MAP
  brandsId?.forEach((brandId) => {
    if (brandId && BRAND_MAP[brandId]) {
      brands.push(BRAND_MAP[brandId]);
    }
  });

  //sort brands by order
  brands = brands.sort((a, b) => a.order - b.order);

  return brands;
};

interface getLegacyUrlParams {
  brandId: string;
  legacyId: string;
  language?: string;
  redirect?: boolean;
}
export const getLegacyUrl = (params?: getLegacyUrlParams): Promise<string | void> => {
  const { brandId, legacyId, language, redirect } = params || {};
  let languageParam = language;
  if (!languageParam) {
    //if language is not passed as parameter --> get it from event provider
    const labelsInfo = eventProvider.getEventProvider()?.get(eventProvider.EventChannel.labelsInfo);
    languageParam = labelsInfo?.lng || "en";
  }
  const mock = window.globalEnvVariables?.USE_MOCK_ACCOUNT;
  const apiUser = new ApiUser();

  const query = {
    brand: brandId || undefined,
    language: languageParam || "",
  };

  return (
    mock ? mockService("/mock/user/legacy.json") : apiUser.priv.getLegacyModuleLink(legacyId, query)
  )
    .then((res: { data: string }) => {
      let url = res.data?.trim();

      if (!url || !isValidUrl(url)) {
        throw new Error("getLegacyUrl - not valid url - " + url?.toString());
      }

      //if the legacy is B2B --> add a query parameter to hide the footer https://bluereplyexp.atlassian.net/browse/VMP-680
      if (legacyId === legacyIdMap.b2b) {
        const separator = url.includes("?") ? "&" : "?";
        url = url + separator + window.globalEnvVariables?.B2B_QUERY_PARAM;
      }

      if (redirect) {
        window.open(url, "_blank");
      }

      //analytics
      sendEventAnalytics({ id: "legacy_access", legacyId: legacyId, brand: brandId });

      return url;
    })
    .catch((err) => {
      console.error("getLegacyUrl", { err });

      //analytics
      sendErrorAnalytics({
        description: "Error getting the legacy url",
        techInfo: err,
      });

      throw err;
    });
};

////// REPLACED BY deleteNotification IN NotificationService.ts //////
// export const alertBFFPasswordChanged = (): Promise<void> => {
//   const mock = window.globalEnvVariables?.USE_MOCK_ACCOUNT;
//   const apiUser = new ApiUser();

//   return (
//     mock
//       ? mockService("/mock/user/passwordChange.json")
//       : apiUser.api.reportUserPasswordChange()
//   )
//     .then((res: { data: void }) => {
//       //200 OK --> no content
//     })
//     .catch((err) => {
//       console.error("alertBFFPasswordChanged", { err });

//       //analytics
//       sendErrorAnalytics({
//         description:
//           "Error alerting the notification center that the user has changed his password",
//         techInfo: err,
//       });

//       throw err;
//     });
// };
